module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"InfyOm Technologies","short_name":"InfyOm","start_url":"/","display":"standalone","icon":"src/assets/images/favicon_icon.png","icons":[{"src":"maskable_icon144.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"maskable_icon_192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"maskable_icon.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"theme_color":"#3dbbfe","background_color":"#3dbbfe","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bc5a0d68b88c4926213d5a666722d5e9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4QQ4XRX9CW"],"gtagConfig":{"cookie_name":"gatsby-gdpr-google-analytics","anonymize_ip":false},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/@tmttn/gatsby-plugin-hubspot-tracking/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"23787203","respectDNT":false,"productionOnly":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
